// import woodShowRoom from 'assets/images/woodShowRoom.png';
// import BannerImage from 'assets/images/BannerImage.png';

// 서대문 형무소 이미지
import sphhBG from 'assets/images/sphhBG.png';
import sphhImageLogo from 'assets/icons/sphhLogo.svg';
import HistoryHallMap from 'assets/images/HistoryHallMap.png';
import HistoryHall from 'assets/images/HistoryHall.png';
import SphhFeedImage1 from 'assets/images/sphhFeedImage1.png';
import SphhFeedImage2 from 'assets/images/sphhFeedImage2.png';
import SphhFeedImage3 from 'assets/images/sphhFeedImage3.png';
import SphhFeedImage4 from 'assets/images/sphhFeedImage4.png';
import SphhFeedImage5 from 'assets/images/sphhFeedImage5.png';
import SphhFeedImage6 from 'assets/images/sphhFeedImage6.png';

// 동원 이미지
import dongwomBG from 'assets/images/dongwonBG.png';
import dongwonImageLogo from 'assets/images/dongwonLogo.png';
import dongwonMall from 'assets/images/dongwonMall.png';
import dongwonKBO from 'assets/images/dongwonKBO.png';
import dongwonTV from 'assets/images/dongwonTV.png';

// 토니모리 이미지
import tonymolyBG from 'assets/images/tonymolyBG.png';
import tonymolyImageLogo from 'assets/images/tonymolyLogo.png';
import tonymolyMall from 'assets/images/tonymolyMall.png';
import tonymolyItem from 'assets/images/tonymolyItem.png';
import tonymolyJamong from 'assets/images/tonymolyJamong.png';

// 파스쿠찌 이미지
import passcucciBG from 'assets/images/passcucciBG.png';
import pascucciImageLogo from 'assets/images/pascucciLogo.png';
import pascucciMall from 'assets/images/pascucciMall.png';
import pascucciItem from 'assets/images/pascucciItem.png';
import pascucciJamong from 'assets/images/pascucciJamong.png';

// 루이까또즈 이미지
import louisquatorzeBG from 'assets/images/louisquatorzeBG.png';
import louisquatorzeImageLogo from 'assets/images/louisquatorzeLogo.png';
import louisquatorzeHistory from 'assets/images/louisquatorzeHistory.png';
import louisquatorzeProduct from 'assets/images/louisquatorzeProduct.png';
import louisquatorzePromotion from 'assets/images/louisquatorzePromotion.png';

// 삼립 이미지
import samlipBG from 'assets/images/samlipBG.png';
import samlipImageLogo from 'assets/images/samlipLogo.png';
import samlipMall from 'assets/images/samlipMall.png';
import samlipSalad from 'assets/images/samlipSalad.png';
import sanlipScoupSalad from 'assets/images/sanlipScoupSalad.png';

// 베베쿡 이미지
import bebecookBG from 'assets/images/bebecookBG.png';
import bebecookImageLogo from 'assets/images/bebecookLogo.png';
import bebeReview from 'assets/images/bebeReview.png';
import bebeYean from 'assets/images/bebeYean.png';
import bebeContack from 'assets/images/bebeContack.png';

// 비비큐 이미지
import bbqImageLogo from 'assets/images/bbqImageLogo.png';
import bbqMall from 'assets/images/bbqMall.png';
import bbqReview from 'assets/images/bbqReview.png';
import bbqEfficacy from 'assets/images/bbqEfficacy.png';
import bbqBG from 'assets/images/bbqBG.png';

// 오프라이스 이미지
import opriceImageLogo from 'assets/images/opriceLogo.png';
import opriceInfo from 'assets/images/opriceInfo.png';
import opriceNobrand from 'assets/images/opriceNobrand.png';
import opriceMukbang from 'assets/images/opriceMukbang.png';
import opriceBG from 'assets/images/opriceBG.png';

// 모나리자 이미지
import monnaLogo from 'assets/images/monnaLogo.png';
import monnaToday from 'assets/images/monnaToday.png';
import monnaMusic from 'assets/images/monnaMusic.png';
import monnaReview from 'assets/images/monnaReview.png';
import monnaBG from 'assets/images/monnaBG.png';

// kif 이미지
import kifLogo from 'assets/images/kifLogo.png';
import kifBG from 'assets/images/kifBG.png';
import kifMelon from 'assets/images/kifMelon.png';
import kifNews from 'assets/images/kifNews.png';
import kifInstaImage from 'assets/images/instaImage.png';

// mhsc 이미지
import mhscLogo from 'assets/images/mhscLogo.png';
import mhscBG from 'assets/images/mhscBG.png';
import mhscTicket1 from 'assets/images/mhscTicket1.png';
import mhscTicket2 from 'assets/images/mhscTicket2.png';

// 고디바 이미지
import godivaLogo from 'assets/images/godivaLogo.png';
import godivaBG from 'assets/images/godivaBG.png';
import godivaStore from 'assets/images/godivaStore.png';
// import godivaZefeto from 'assets/images/godivaZefeto.png';
import godivaEstation from 'assets/images/godivaEstation.png';

// 워터 제네시스 이미지
import watergLogo from 'assets/images/watergLogo.png';
import watergBG from 'assets/images/watergBG.png';
import watergYoutube from 'assets/images/watergYoutube.png';
import watergEarth from 'assets/images/watergEarth.png';
import watergAS from 'assets/images/watergAS.png';
import watergEnvex from 'assets/images/watergEnvex.png';

// bts_guess 이미지
import btsLogo from 'assets/images/btsLogo.png';
import btsBG from 'assets/images/btsBG.png';
import btsHome from 'assets/images/btsHome.png';
import btsInsta from 'assets/images/btsInsta.png';
import btsFacebook from 'assets/images/btsFacebook.png';
import btsYoutube from 'assets/images/btsYoutube.png';

// isaac 이미지
import isaacLogo from 'assets/images/isaacLogo.png';
import isaacBG from 'assets/images/isaacBG.png';
import isaacOrder from 'assets/images/isaacOrder.png';
import isaacMucbang from 'assets/images/isaacMucbang.png';
import isaacEvent from 'assets/images/isaacEvent.png';

// 정관장 이미지
import kgcshopLogo from 'assets/images/kgcshopLogo.png';
import kgcshopBG from 'assets/images/kgcshopBG.png';
import kgcshopBest from 'assets/images/kgcshopBest.png';
import kgcshopPromotion from 'assets/images/kgcshopPromotion.png';
import kgcshopEvent from 'assets/images/kgcshopEvent.png';

// 인셀덤 이미지
import incelldermLinkLogo from 'assets/images/incelldermLinkLogo.png';
import incelldermBG from 'assets/images/incelldermBG.png';
import incelldermSignup from 'assets/images/incelldermSignup.png';
import incelldermCatalog from 'assets/images/incelldermCatalog.png';
import incelldermYoutube from 'assets/images/incelldermYoutube.png';

// 까르띠에 100000번대
import cartierWineBG from 'assets/images/cartierWineBG.png';
import cartierWineLogo from 'assets/images/cartierWineLogo.png';
import cartierWineBell from 'assets/images/cartierWineBell.png';
import cartierWineEye from 'assets/images/cartierWineEye.png';
import cartierWineBoxing from 'assets/images/cartierWineBoxing.png';

// 까르띠에 000000번대
import cartierGrayLogo from 'assets/images/cartierGrayLogo.png';
import cartierGrayBell from 'assets/images/cartierGrayBell.png';
import cartierGrayEye from 'assets/images/cartierGrayEye.png';
import cartierGrayBoxing from 'assets/images/cartierGrayBoxing.png';
import cartierGrayBG from 'assets/images/cartierGrayBG.png';

// 로젠택배
import logenBG from 'assets/images/logenBG.png';
import logenLogo from 'assets/images/logenLogo.png';
import logenCall from 'assets/images/logenCall.png';
import logenPeople from 'assets/images/logenPeople.png';
import logenOffice from 'assets/images/logenOffice.png';

// 샤넬
import chanelBG from 'assets/images/chanelBG.png';
import chanelLogo from 'assets/images/chanelLogo.png';
import chanelHistory from 'assets/images/chanelHistory.png';
import chanelProduct from 'assets/images/chanelProduct.png';
import chanelPromotion from 'assets/images/chanelPromotion.png';

// 롯데 상품권
import lotteShoppingBG from 'assets/images/lotteShoppingBG.png';
import lotteShoppingLogo from 'assets/images/lotteShoppingLogo.png';
import lotteShoppingBuy from 'assets/images/lotteShoppingBuy.png';
import lotteShoppingList from 'assets/images/lotteShoppingList.png';
import lotteShoppingInfo from 'assets/images/lotteShoppingInfo.png';

// pfzer
import pfzerBG from 'assets/images/pfzerBG.png';
import pfzerLogo from 'assets/images/pfzerLogo.png';
import pfzerCompany from 'assets/images/pfzerCompany.png';
import pfzerNew from 'assets/images/pfzerNew.png';
import pfzerProduct from 'assets/images/pfzerProduct.png';

// mamonde
import mamondeBG from 'assets/images/mamondeBG.png';
import mamondeLogo from 'assets/images/mamondeLogo.png';
import mamondeBest from 'assets/images/mamondeBest.png';
import mamondeSkin from 'assets/images/mamondeSkin.png';
import mamondeMakeUp from 'assets/images/mamondeMakeUp.png';

// spicyRamen
import spicyRamenBG from 'assets/images/spicyRamenBG.png';
import spicyRamenLogo from 'assets/images/spicyRamenLogo.png';
import spicyRamenProduct from 'assets/images/spicyRamenProduct.png';
import spicyRamenBrand from 'assets/images/spicyRamenBrand.png';
import spicyRamenContact from 'assets/images/spicyRamenContact.png';

//samsung buds
import samsungBudsBG from 'assets/images/samsungBudsBG.png';
import samsungBudsLogo from 'assets/images/samsungBudsLogo.png';
import samsungBuds2 from 'assets/images/samsungBuds2.png';
import samsungBuds from 'assets/images/samsungBuds.png';
import samsungEdition from 'assets/images/samsungEdition.png';

// penmax
import penmaxBG from 'assets/images/penmaxBG.png';
import penmaxLogo from 'assets/images/penmaxLogo.png';
import penmaxProduct from 'assets/images/penmaxProduct.png';
import penmaxNotice from 'assets/images/penmaxNotice.png';
import penmaxCantact from 'assets/images/penmaxCantact.png';

// marvel
import marvelBG from 'assets/images/marvelBG.png';
import marvelLogo from 'assets/images/marvelLogo.png';
import marvelMovies from 'assets/images/marvelMovies.png';
import marvelComics from 'assets/images/marvelComics.png';
import marvelNews from 'assets/images/marvelNews.png';

// cjlogistics
import cjlogisticsBG from 'assets/images/cjlogisticsBG.png';
import cjlogisticsLogo from 'assets/images/cjlogisticsLogo.png';
import cjlogisticsConfirm from 'assets/images/cjlogisticsComfirm.png';
import cjlogisticsInfo from 'assets/images/cjlogisticsInfo.png';
import cjlogisticsContact from 'assets/images/cjlogisticsContact.png';

// mobis
import mobisBG from 'assets/images/mobisBG.png';
import mobisLogo from 'assets/images/mobisLogo.png';
import mobisInfo from 'assets/images/mobisInfo.png';
import mobisCareer from 'assets/images/mobisCareer.png';
import mobisNews from 'assets/images/mobisNews.png';

// vangoghmuseum
import vangoghmuseumBG from 'assets/images/vangoghmuseumBG.png';
import vangoghmuseumLogo from 'assets/images/vangoghmuseumLogo.png';
import vangoghmuseumTickets from 'assets/images/vangoghmuseumTickets.png';
import vangoghmuseumArt from 'assets/images/vangoghmuseumArt.png';
import vangoghmuseumWebstore from 'assets/images/vangoghmuseumWebstore.png';

// muzik
import muzikBG from 'assets/images/muzikBG.png';
import muzikLogo from 'assets/images/muzikLogo.png';
import muzikEvent from 'assets/images/muzikEvent.png';
import muzikMusic from 'assets/images/muzikMusic.png';
import muzikMagazin from 'assets/images/muzikMagazin.png';

// louvre
import louvreBG from 'assets/images/louvreBG.png';
import louvreLogo from 'assets/images/louvreLogo.png';
import louvreVisiter from 'assets/images/louvreVisiter.png';
import louvreDecouvrir from 'assets/images/louvreDecouvrir.png';
import louvreEnceMoment from 'assets/images/louvreEnceMoment.png';

// gwanak
import gwanakBG from 'assets/images/gwanakBG.png';
import gwanakLogo from 'assets/images/gwanakLogo.png';
import gwanakInfo from 'assets/images/gwanakInfo.png';
import gwanakSave from 'assets/images/gwanakSave.png';
import gwanakContact from 'assets/images/gwanakContact.png';

// looxLooe
import looxLooeBG from 'assets/images/looxLooeBG.png';
import looxLooeLogo from 'assets/images/looxLooeLogo.png';
import looxLooeProduct from 'assets/images/looxLooeProduct.png';
import looxLooeLook from 'assets/images/looxLooeLook.png';
import looxLooeItem from 'assets/images/looxLooeItem.png';

// baba
import babaBG from 'assets/images/babaBG.png';
import babaLogo from 'assets/images/babaLogo.png';
import babaProduct from 'assets/images/babaProduct.png';
import babaNew from 'assets/images/babaNew.png';
import babaIzzat from 'assets/images/babaIzzat.png';

// theoriginal
import theoriginalBG from 'assets/images/theoriginalBG.png';
import theoriginalLogo from 'assets/images/theoriginalLogo.png';
import theoriginalHome from 'assets/images/theoriginalHome.png';
import theoriginalNew from 'assets/images/theoriginalNew.png';

// whoau
import whoauBG from 'assets/images/whoauBG.png';
import whoauLogo from 'assets/images/whoauLogo.png';
import whoauNew from 'assets/images/whoauNew.png';
import whoauBest from 'assets/images/whoauBest.png';
import whoauEvent from 'assets/images/whoauEvent.png';

// tamnaalesLinkList
import tamnaalesBG from 'assets/images/tamnaalesBG.png';
import tamnaalesLogo from 'assets/images/tamnaalesLogo.png';
import tamnaalesBlog from 'assets/images/tamnaalesBlog.png';
import tamnaalesTistory from 'assets/images/tamnaalesTistory.png';

export const sphhLinkList = {
  profile: {
    sphhLogo: sphhImageLogo,
    nickname: '',
  },
  theme: {
    themeName: 'sphh',
    backgroundImage: sphhBG,
    font: 'SeoulHangang',
  },
  list: [
    {
      id: 2,
      name: '역사관 VR보기',
      url: 'http://www.sdm.go.kr/360vr/05_indepen_fort/index.html',
      thumbnail: HistoryHall,
      category: 'Homepage',
    },
    {
      id: 3,
      name: '역사관 어제와 오늘',
      url: 'https://sphh.sscmc.or.kr/hall/hallview_01.php',
      thumbnail: HistoryHallMap,
      category: 'Homepage',
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/sphh1908/',
      urlId: 'sphh1908',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/sdmprison',
      urlId: 'sdmprison',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/channel/UCqBEkHNlmWwYa4B_vdW40Lw',
      urlId: 'channel/UCqBEkHNlmWwYa4B_vdW40Lw',
    },
    {
      category: 'Homepage',
      url: 'https://sphh.sscmc.or.kr/',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },

    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },

    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [
    {
      image: SphhFeedImage1,
      name: 'SphhFeedImage1',
    },
    {
      image: SphhFeedImage2,
      name: 'SphhFeedImage2',
    },
    {
      image: SphhFeedImage3,
      name: 'SphhFeedImage3',
    },
    {
      image: SphhFeedImage4,
      name: 'SphhFeedImage4',
    },
    {
      image: SphhFeedImage5,
      name: 'SphhFeedImage5',
    },
    {
      image: SphhFeedImage6,
      name: 'SphhFeedImage6',
    },
  ],
};

export const dongwonLinkList = {
  profile: {
    dongwonLogo: dongwonImageLogo,
    nickname: 'dongwonfnb',
  },
  theme: {
    backgroundImage: dongwomBG,
    themeName: 'dongwon',
    font: 'CookieRun-Regular',
  },
  list: [
    {
      id: 1,
      name: '동원몰',
      url: 'https://www.dongwonmall.com/',
      thumbnail: dongwonMall,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '동원참치 X KBO 콜라보레이션',
      url: 'https://www.youtube.com/watch?v=LPYz7PfiU4M',
      thumbnail: dongwonKBO,
      category: 'Youtube',
      urlId: 'watch?v=LPYz7PfiU4M',
    },
    {
      id: 3,
      name: '동원의 맛 꿀팁',
      url: 'https://www.youtube.com/shorts/jMat8JFoVUE',
      thumbnail: dongwonTV,
      category: 'Youtube',
      urlId: 'shorts/jMat8JFoVUE',
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/dongwonfnb_food/',
      urlId: 'dongwonfnb_food',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/dongwongroup/',
      urlId: 'dongwongroup',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/c/DongwonFnB',
      urlId: 'c/DongwonFnB',
    },
    {
      category: 'Homepage',
      url: 'https://www.dongwonfnb.com/services/index;jsessionid=64B64ED769CD0FD676B34E0DFB33A170#this',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },

    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },

    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};

export const tonymolyLinkList = {
  profile: {
    dongwonLogo: tonymolyImageLogo,
    nickname: 'tonymoly',
  },
  theme: {
    backgroundImage: tonymolyBG,
    themeName: 'dongwon',
    font: 'CookieRun-Regular',
  },
  list: [
    {
      id: 1,
      name: '토니모리몰',
      url: 'https://www.tonystreet.com/',
      thumbnail: tonymolyMall,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '토니모리 추천템',
      url: 'https://www.youtube.com/watch?v=YG3gF0Ly2Wc',
      thumbnail: tonymolyItem,
      category: 'Youtube',
      urlId: 'watch?v=YG3gF0Ly2Wc',
    },
    {
      id: 3,
      name: '유자가 자몽했다',
      url: 'https://www.youtube.com/watch?v=SHgXOKM1MgQ',
      thumbnail: tonymolyJamong,
      category: 'Youtube',
      urlId: 'watch?v=SHgXOKM1MgQ',
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/tonymoly_street/',
      urlId: 'tonymoly_street',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/tonymoly.kr',
      urlId: 'tonymoly.kr',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/user/tonymolykr',
      urlId: 'user/tonymolykr',
    },
    {
      category: 'Homepage',
      url: 'http://corp.tonymoly.com/',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },

    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },

    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [
    {
      image: SphhFeedImage1,
      name: 'SphhFeedImage1',
    },
    {
      image: SphhFeedImage2,
      name: 'SphhFeedImage2',
    },
    {
      image: SphhFeedImage3,
      name: 'SphhFeedImage3',
    },
    {
      image: SphhFeedImage4,
      name: 'SphhFeedImage4',
    },
    {
      image: SphhFeedImage5,
      name: 'SphhFeedImage5',
    },
    {
      image: SphhFeedImage6,
      name: 'SphhFeedImage6',
    },
  ],
};

export const pascucciLinkList = {
  profile: {
    dongwonLogo: pascucciImageLogo,
    nickname: 'pascucci',
  },
  theme: {
    backgroundImage: passcucciBG,
    themeName: 'dongwon',
    font: 'CookieRun-Regular',
  },
  list: [
    {
      id: 1,
      name: '파스쿠찌 추천메뉴',
      url: 'https://www.youtube.com/watch?v=p2pt0VGOUd4',
      thumbnail: pascucciMall,
      category: 'Youtube',
      urlId: 'watch?v=p2pt0VGOUd4',
    },

    {
      id: 2,
      name: '이벤트 및 메뉴 소개',
      url: 'https://ggoolti.tistory.com/181',
      thumbnail: pascucciItem,
      category: 'Homepage',
      urlId: null,
    },

    {
      id: 3,
      name: '파스쿠찌 매장안내',
      url: 'https://www.caffe-pascucci.co.kr/store/storeList.asp',
      thumbnail: pascucciJamong,

      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/pascucci_kr',
      urlId: 'pascucci_kr',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/pascucci1883/',
      urlId: 'pascucci1883',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/channel/UCHKRIWTWjq0uzJOAm6KFHOg',
      urlId: 'channel/UCHKRIWTWjq0uzJOAm6KFHOg',
    },
    {
      category: 'Homepage',
      url: 'https://www.caffe-pascucci.co.kr/',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },

    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },

    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};

export const louisquatorzeLinkList = {
  profile: {
    dongwonLogo: louisquatorzeImageLogo,
    nickname: 'louis quatorze',
  },
  theme: {
    backgroundImage: louisquatorzeBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '루이까또즈 브랜드 역사',
      url: 'https://www.youtube.com/watch?v=uqBIX-_90lM',
      thumbnail: louisquatorzeHistory,
      category: 'Youtube',
      urlId: 'watch?v=uqBIX-_90lM',
    },

    {
      id: 2,
      name: '루이까또즈 추천상품',
      url: 'https://pink29ana.com/%EB%A3%A8%EC%9D%B4%EA%B9%8C%EB%98%90%EC%A6%88%EA%B0%80%EB%B0%A9-%EC%B6%94%EC%B2%9C-%EC%88%9C%EC%9C%84-top10/',
      thumbnail: louisquatorzeProduct,
      category: 'Homepage',
      urlId: null,
    },

    {
      id: 3,
      name: '루이까또즈 프로모션',
      url: 'https://www.stylelq.com/fr/louissquare/promotionList.do?cate=P',
      thumbnail: louisquatorzePromotion,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/louisquatorze_official/',
      urlId: 'louisquatorze_official',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/louisquatorze.kr/',
      urlId: 'louisquatorze.kr',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/channel/UCVC-36rbSkODS-gbVijTS-w',
      urlId: 'channel/UCVC-36rbSkODS-gbVijTS-w',
    },
    {
      category: 'Homepage',
      url: 'https://www.stylelq.com/',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },

    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },

    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};

export const samlipsaladLinkList = {
  profile: {
    dongwonLogo: samlipImageLogo,
    nickname: 'samlip',
  },
  theme: {
    backgroundImage: samlipBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '삼립몰',
      url: 'https://brand.naver.com/samlip',
      thumbnail: samlipMall,
      category: 'Homepage',
      urlId: 'null',
    },

    {
      id: 2,
      name: '삼립 스쿱샐러드 활용 레시피',
      url: 'https://www.youtube.com/watch?v=gDEv2PXULd0',
      thumbnail: samlipSalad,
      category: 'Youtube',
      urlId: 'watch?v=gDEv2PXULd0',
    },

    {
      id: 3,
      name: '삼립 스쿱샐러드 후기',
      url: 'https://blog.naver.com/lovelydl22/222438206943',
      thumbnail: sanlipScoupSalad,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/p/CCBAIv4lMyr/',
      urlId: 'CCBAIv4lMyr',
    },
    {
      category: 'Facebook',
      url: 'https://ko-kr.facebook.com/samlipgf/',
      urlId: 'samlipgf',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/channel/UC88W-nkd_7G9S7u-2xlX4DQ',
      urlId: 'channel/UC88W-nkd_7G9S7u-2xlX4DQ',
    },
    {
      category: 'Homepage',
      url: 'https://spcsamlip.co.kr/',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },

    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },

    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};

export const bebecookLinkList = {
  profile: {
    dongwonLogo: bebecookImageLogo,
    nickname: 'bebecook',
  },
  theme: {
    backgroundImage: bebecookBG,
    themeName: 'dongwon',
    font: 'CookieRun-Regular',
  },
  list: [
    {
      id: 1,
      name: '순살연어구이 후기',
      url: 'https://blog.naver.com/yjyj7595/222391774098',
      thumbnail: bebeReview,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '어린이에게도 좋은 연어',
      url: 'https://choodamedu.tistory.com/entry/%EC%97%B0%EC%96%B4-%ED%9A%A8%EB%8A%A5-%EC%97%AC%EC%A0%84%ED%9E%88-%EB%86%80%EB%9D%BC%EC%9A%B4-12%EA%B0%80%EC%A7%80',
      thumbnail: bebeYean,
      category: 'Homepage',
      urlId: null,
    },

    {
      id: 3,
      name: '물어베베 #연령별섭취요령',
      url: 'https://www.youtube.com/watch?v=PKjasLy_IdU',
      thumbnail: bebeContack,
      category: 'Youtube',
      urlId: 'watch?v=PKjasLy_IdU',
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/bebecook/',
      urlId: 'bebecook',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/bebecook1999/',
      urlId: 'bebecook1999',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/channel/UCYDzuw4d0eH998fFjHbHokQ',
      urlId: 'channel/UCYDzuw4d0eH998fFjHbHokQ',
    },
    {
      category: 'Homepage',
      url: 'https://www.bebecook.com/',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },

    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },

    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};

export const bbqLinkList = {
  profile: {
    dongwonLogo: bbqImageLogo,
    nickname: 'bbq',
  },
  theme: {
    backgroundImage: bbqBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: 'bbq 몰',
      url: 'https://mall.bbq.co.kr/goods/view?no=62#goods_exchange_guide',
      thumbnail: bbqMall,
      category: 'Homepage',
      urlId: null,
    },

    {
      id: 2,
      name: 'bbq 삼계탕 후기',
      url: 'https://blog.naver.com/haesay0414/222463226258',
      thumbnail: bbqReview,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: 'bbq Top3 치킨',
      url: 'https://www.youtube.com/watch?v=P8bdatg4eHQ',
      thumbnail: bbqEfficacy,
      category: 'Youtube',
      urlId: 'watch?v=uqBIX-_90lM',
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/bbq_offi/',
      urlId: 'bbq_offi',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/BBQ치킨-110863401617269/',
      urlId: 'BBQ치킨-110863401617269',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/user/BBQMobile',
      urlId: 'user/BBQMobile',
    },
    {
      category: 'Homepage',
      url: 'https://www.bbq.co.kr/main.asp',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },

    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },

    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};

export const opriceLinkList = {
  profile: {
    dongwonLogo: opriceImageLogo,
    nickname: 'oprice',
  },
  theme: {
    backgroundImage: opriceBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '오프라이스의 모든 것',
      url: 'https://m-kimsclub.elandmall.com/shop/initPlanShop.action?disp_ctg_no=2004442078',
      thumbnail: opriceInfo,
      category: 'Homepage',
      urlId: null,
    },

    {
      id: 2,
      name: '노브랜O보다 가성비 좋은곳',
      url: 'https://www.youtube.com/watch?v=0AhBM2SbdEY',
      thumbnail: opriceNobrand,
      category: 'Youtube',
      urlId: 'watch?v=0AhBM2SbdEY',
    },
    {
      id: 3,
      name: '오프라이스 4종 먹방!',
      url: 'https://www.youtube.com/watch?v=4iMZbHLre6U',
      thumbnail: opriceMukbang,
      category: 'Youtube',
      urlId: 'watch?v=4iMZbHLre6U',
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/oprice_official/',
      urlId: 'oprice_official',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/오프라이스-576680179391346',
      urlId: '오프라이스-576680179391346',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/user/elandgroup',
      urlId: 'user/elandgroup',
    },
    {
      category: 'Homepage',
      url: 'https://www.elandmall.com/dispctg/initBrandShop.action?brand_no=1900014016',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },

    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },

    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};

export const monnaLinkList = {
  profile: {
    dongwonLogo: monnaLogo,
    nickname: 'monna',
  },
  theme: {
    backgroundImage: monnaBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '오늘의 공연 - 전시',
      url: 'https://www.sac.or.kr/site/main/show/todayShow',
      thumbnail: monnaToday,
      category: 'Homepage',
      urlId: null,
    },

    {
      id: 2,
      name: '여름 음악 축제',
      url: 'https://www.youtube.com/watch?v=7uts7LTyh5Y',
      thumbnail: monnaMusic,
      category: 'Youtube',
      urlId: 'watch?v=7uts7LTyh5Y',
    },
    {
      id: 3,
      name: '예술의 전당 방문 후기',
      url: 'https://musicalcurator.tistory.com/57',
      thumbnail: monnaReview,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/seoul_arts_center/',
      urlId: 'seoul_arts_center',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/SeoulArtsCenter.Korea',
      urlId: 'SeoulArtsCenter.Korea',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/channel/UCUn8h6aG6rCM1zvXKIuNpVQ',
      urlId: 'channel/UCUn8h6aG6rCM1zvXKIuNpVQ',
    },
    {
      category: 'Homepage',
      url: 'https://www.sac.or.kr/site/main/home',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },

    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },

    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};

export const kifLinkList = {
  profile: {
    dongwonLogo: kifLogo,
    nickname: '2022kif',
  },
  theme: {
    backgroundImage: kifBG,
    themeName: 'kiff',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '2022 KIF 홈페이지',
      url: 'https://www.2022kif.com',
      thumbnail: kifNews,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '멜론티켓',
      url: 'https://ticket.melon.com',
      thumbnail: kifMelon,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '인스타그램',
      url: 'https://www.instagram.com/2022kif/',
      thumbnail: kifInstaImage,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/2022kif/',
      urlId: '2022kif',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/2022kif',
      urlId: '2022kif',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/channel/UCfKQZeOwE4Ukj0-BYnmXQSw',
      urlId: 'channel/UCfKQZeOwE4Ukj0-BYnmXQSw',
    },
    {
      category: 'Homepage',
      url: 'https://www.2022kif.com',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },

    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },

    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};

export const mhscLinkList = {
  profile: {
    dongwonLogo: mhscLogo,
    nickname: '2022mhsc',
  },
  theme: {
    backgroundImage: mhscBG,
    // themeName: 'mhsc',
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: 'MH슈퍼콘서트 X 고고90 홈페이지',
      url: 'https://mhandgogo90.modoo.at/',
      thumbnail: mhscLogo,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '티켓예매1',
      url: 'https://mticket.interpark.com/Notice/NoticeView?bn=34&no=44623',
      thumbnail: mhscTicket1,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '티켓예매2',
      url: 'https://smartstore.naver.com/smartmoms/products/7363862796',
      thumbnail: mhscTicket2,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/mh_gogo90',
      urlId: 'mh_gogo90',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/profile.php?id=100086734178263',
      urlId: 'profile.php?id=100086734178263',
    },
    // {
    //   category: 'Youtube',
    //   url: 'https://www.youtube.com/channel/UCFB6ZUKG6l6pB0wvDvxjftg',
    //   urlId: 'channel/UCFB6ZUKG6l6pB0wvDvxjftg',
    // },
    {
      category: 'Homepage',
      url: 'https://mhandgogo90.modoo.at/',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },

    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },

    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};

export const godivaList = {
  profile: {
    dongwonLogo: godivaLogo,
    nickname: 'godiva',
  },
  theme: {
    backgroundImage: godivaBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '고디바 매장 찾기',
      url: 'https://search.naver.com/search.naver?where=nexearch&sm=top_hty&fbm=1&ie=utf8&query=%EA%B3%A0%EB%94%94%EB%B0%94',
      thumbnail: godivaStore,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: `고디바 홈페이지`,
      url: 'https://www.godiva.kr/',
      thumbnail: godivaLogo,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '고디바 X 이스테이션 특별가 한정 판매',
      url: 'https://estation.siig.io/godiva',
      thumbnail: godivaEstation,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/godivakorea',
      urlId: 'godivakorea',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/GODIVAKR',
      urlId: 'GODIVAKR',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/channel/UCFB6ZUKG6l6pB0wvDvxjftg',
      urlId: 'channel/UCFB6ZUKG6l6pB0wvDvxjftg',
    },
    {
      category: 'Homepage',
      url: 'https://www.godiva.kr',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },

    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },

    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};

export const watergList = {
  profile: {
    dongwonLogo: watergLogo,
    nickname: 'waterg',
  },
  theme: {
    backgroundImage: watergBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '워터 제네시스 홈페이지',
      url: 'https://waterg.net/index.php',
      thumbnail: watergLogo,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: 'A/S신청',
      url: 'https://waterg.net/sub/sub04_01.php',
      thumbnail: watergAS,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '유튜브',
      url: 'https://www.youtube.com/channel/UCNeDnCr0ZgIY-giyxW-zXmQ/featured',
      thumbnail: watergYoutube,
      category: 'Youtube',
      urlId: 'channel/UCNeDnCr0ZgIY-giyxW-zXmQ/featured',
    },
    {
      id: 4,
      name: 'ENVEX 참가 동영상',
      url: 'https://www.youtube.com/watch?v=gTfyLPNDmk4&t=55s',
      thumbnail: watergEnvex,
      category: 'Youtube',
      urlId: 'watch?v=gTfyLPNDmk4&t=55s',
    },
    {
      id: 5,
      name: '클린지니',
      url: 'https://waterg.net/sub/sub02_01.php',
      thumbnail: watergEarth,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    // {
    //   category: 'Instagram',
    //   url: 'https://www.instagram.com/godivakorea',
    //   urlId: 'godivakorea',
    // },
    // {
    //   category: 'Facebook',
    //   url: 'https://www.facebook.com/GODIVAKR',
    //   urlId: 'GODIVAKR',
    // },
    // {
    //   category: 'Youtube',
    //   url: 'https://www.youtube.com/channel/UCFB6ZUKG6l6pB0wvDvxjftg',
    //   urlId: 'channel/UCFB6ZUKG6l6pB0wvDvxjftg',
    // },
    // {
    //   category: 'Homepage',
    //   url: 'https://www.godiva.kr',
    // },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};

export const btsLinkList = {
  profile: {
    dongwonLogo: btsLogo,
    nickname: 'bts_guess',
  },
  theme: {
    backgroundImage: btsBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: 'BTS홈페이지',
      url: 'https://ibighit.com/bts/kor/',
      thumbnail: btsHome,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: 'BTS 인스타그램',
      url: 'https://www.instagram.com/bts.bighitofficial',
      thumbnail: btsInsta,
      category: 'Instagram',
      urlId: 'bts.bighitofficial',
    },
    {
      id: 3,
      name: 'BTS 페이스북',
      url: 'https://www.facebook.com/bangtan.official',
      thumbnail: btsFacebook,
      category: 'Facebook',
      urlId: 'bangtan.official',
    },
    {
      id: 4,
      name: 'BTS 유튜브',
      url: 'https://www.youtube.com/c/BANGTANTV',
      thumbnail: btsYoutube,
      category: 'Youtube',
      urlId: 'c/BANGTANTV',
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/guess',
      urlId: 'guess',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/GUESSSouthKorea',
      urlId: 'GUESSSouthKorea',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/channel/UCTS9JlGeL7zeNcZZLrzD6bg',
      urlId: 'channel/UCTS9JlGeL7zeNcZZLrzD6bg',
    },
    {
      category: 'Homepage',
      url: 'https://www.guesskorea.com/main/main.php',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};

export const isaacLinkList = {
  profile: {
    dongwonLogo: isaacLogo,
    nickname: 'isaac',
  },
  theme: {
    backgroundImage: isaacBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '주문하기',
      url: 'https://www.isaac-toast.co.kr/menu/menu.html?depth1=1',
      thumbnail: isaacOrder,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '이삭토스트 3종 먹방',
      url: 'https://www.youtube.com/watch?v=P31LenLjzZo',
      thumbnail: isaacMucbang,
      category: 'Youtube',
      urlId: 'watch?v=P31LenLjzZo',
    },
    {
      id: 3,
      name: '이벤트 프로모션',
      url: 'https://www.isaac-toast.co.kr/news/promotion.html',
      thumbnail: isaacEvent,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/isaactoast.official',
      urlId: 'isaactoast.official',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/isaactoast.online',
      urlId: 'isaactoast.online',
    },
    // {
    //   category: 'Youtube',
    //   url: 'https://www.youtube.com/channel/UCTS9JlGeL7zeNcZZLrzD6bg',
    //   urlId: 'channel/UCTS9JlGeL7zeNcZZLrzD6bg',
    // },
    {
      category: 'Homepage',
      url: 'https://www.isaac-toast.co.kr/main/main.html',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};

export const kgcshopLinkList = {
  profile: {
    dongwonLogo: kgcshopLogo,
    nickname: 'kgcshop',
  },
  theme: {
    backgroundImage: kgcshopBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '정관장몰 베스트',
      url: 'https://www.kgcshop.co.kr/event/goodsBest?paramGnbId=30',
      thumbnail: kgcshopBest,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '정관장몰 기획전',
      url: 'https://www.kgcshop.co.kr/event/promotionList?paramGnbId=27#page1',
      thumbnail: kgcshopPromotion,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '정관장몰 이벤트',
      url: 'https://www.kgcshop.co.kr/event/goodsPromotion?dispId=5660',
      thumbnail: kgcshopEvent,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/kgc_official_',
      urlId: 'kgc_official_',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/KGCsamsamstory',
      urlId: 'KGCsamsamstory',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/user/samsamstory/featured',
      urlId: 'user/samsamstory/featured',
    },
    {
      category: 'Homepage',
      url: 'https://www.kgcshop.co.kr',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
export const kgcshop200001LinkList = {
  profile: {
    dongwonLogo: kgcshopLogo,
    nickname: '200001',
  },
  theme: {
    backgroundImage: kgcshopBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '정관장몰 베스트',
      url: 'https://www.kgcshop.co.kr/event/goodsBest?paramGnbId=30',
      thumbnail: kgcshopBest,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '정관장몰 기획전',
      url: 'https://www.kgcshop.co.kr/event/promotionList?paramGnbId=27#page1',
      thumbnail: kgcshopPromotion,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '정관장몰 이벤트',
      url: 'https://www.kgcshop.co.kr/event/goodsPromotion?dispId=5660',
      thumbnail: kgcshopEvent,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/kgc_official_',
      urlId: 'kgc_official_',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/KGCsamsamstory',
      urlId: 'KGCsamsamstory',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/user/samsamstory/featured',
      urlId: 'user/samsamstory/featured',
    },
    {
      category: 'Homepage',
      url: 'https://www.kgcshop.co.kr',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
export const kgcshop200002LinkList = {
  profile: {
    dongwonLogo: kgcshopLogo,
    nickname: '200002',
  },
  theme: {
    backgroundImage: kgcshopBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '정관장몰 베스트',
      url: 'https://www.kgcshop.co.kr/event/goodsBest?paramGnbId=30',
      thumbnail: kgcshopBest,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '정관장몰 기획전',
      url: 'https://www.kgcshop.co.kr/event/promotionList?paramGnbId=27#page1',
      thumbnail: kgcshopPromotion,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '정관장몰 이벤트',
      url: 'https://www.kgcshop.co.kr/event/goodsPromotion?dispId=5660',
      thumbnail: kgcshopEvent,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/kgc_official_',
      urlId: 'kgc_official_',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/KGCsamsamstory',
      urlId: 'KGCsamsamstory',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/user/samsamstory/featured',
      urlId: 'user/samsamstory/featured',
    },
    {
      category: 'Homepage',
      url: 'https://www.kgcshop.co.kr',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
export const incelldermLinkList = {
  profile: {
    dongwonLogo: incelldermLinkLogo,
    nickname: 'incellderm_official',
  },
  theme: {
    backgroundImage: incelldermBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '회원가입',
      url: 'https://myoffice.rimankorea.com/member/login',
      thumbnail: incelldermSignup,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '인셀덤 카탈로그',
      url: 'https://incellderm.rimankorea.com/products\n',
      thumbnail: incelldermCatalog,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '인셀덤 성공사례',
      url: 'https://www.youtube.com/watch?v=sxT2hveIyiQ',
      thumbnail: incelldermYoutube,
      category: 'Youtube',
      urlId: 'watch?v=sxT2hveIyiQ',
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/incellderm.official',
      urlId: 'incellderm.official',
    },
    // {
    //   category: 'Facebook',
    //   url: 'https://www.facebook.com/KGCsamsamstory',
    //   urlId: 'KGCsamsamstory',
    // },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/@incellderm2166',
      urlId: '@incellderm2166',
    },
    {
      category: 'Homepage',
      url: 'https://incellderm.rimankorea.com',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};

export const chanelLinkList = {
  profile: {
    dongwonLogo: chanelLogo,
    nickname: 'chanel',
  },
  theme: {
    backgroundImage: chanelBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '샤넬 브랜드 역사',
      url: 'https://www.chanel.com/kr/about-chanel/the-history/',
      thumbnail: chanelHistory,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '샤넬 추천 상품',
      url: 'https://www.chanel.com/kr/high-jewelry/',
      thumbnail: chanelProduct,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '샤넬 프로모션',
      url: 'https://www.chanel.com/kr/haute-couture/',
      thumbnail: chanelPromotion,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/chanelofficial',
      urlId: 'chanelofficial',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/ChanelKR',
      urlId: 'ChanelKR',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/user/CHANEL',
      urlId: 'user/CHANEL',
    },
    {
      category: 'Homepage',
      url: 'https://www.chanel.com/kr/',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
export const lotteShoppingLinkList = {
  profile: {
    dongwonLogo: lotteShoppingLogo,
    nickname: 'lotteshopping',
  },
  theme: {
    backgroundImage: lotteShoppingBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '상품권 구입안내',
      url: 'https://www.lotteshopping.com/service/voucherBuy',
      thumbnail: lotteShoppingBuy,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '상품권 권종',
      url: 'https://www.lotteshopping.com/service/voucher',
      thumbnail: lotteShoppingList,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '상품권 사용안내',
      url: 'https://www.lotteshopping.com/service/voucherUse',
      thumbnail: lotteShoppingInfo,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/lotteshopping',
      urlId: 'lotteshopping',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/LOTTEshopping',
      urlId: 'LOTTEshopping',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/channel/UCpBkdV5sGB9v80H8XTewtcg',
      urlId: 'channel/UCpBkdV5sGB9v80H8XTewtcg',
    },
    {
      category: 'Homepage',
      url: 'https://www.lotteshopping.com/',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
export const pfizerShoppingLinkList = {
  profile: {
    dongwonLogo: pfzerLogo,
    nickname: 'pfizer',
  },
  theme: {
    backgroundImage: pfzerBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '회사 소개',
      url: 'https://www.pfizer.co.kr/about-us/기업-소개',
      thumbnail: pfzerCompany,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '새로운 소식',
      url: 'https://www.pfizer.co.kr/media/화이자-소식',
      thumbnail: pfzerNew,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '한국화이자제약 제품',
      url: 'https://www.pfizer.co.kr/products-list',
      thumbnail: pfzerProduct,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    // {
    //   category: 'Instagram',
    //   url: 'https://www.instagram.com/',
    //   urlId: '',
    // },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/Pfizer',
      urlId: 'Pfizer',
    },
    // {
    //   category: 'Youtube',
    //   url: 'https://www.youtube.com/',
    //   urlId: '',
    // },
    {
      category: 'Homepage',
      url: 'https://www.pfizer.co.kr/',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
export const mamondeLinkList = {
  profile: {
    dongwonLogo: mamondeLogo,
    nickname: 'mamonde',
  },
  theme: {
    backgroundImage: mamondeBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '베스트 & 신제품',
      url: 'https://www.mamonde.com/kr/ko/product/bestnew/index.html',
      thumbnail: mamondeBest,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '스킨케어 전체보기',
      url: 'https://www.mamonde.com/kr/ko/product/skincare/category/all/index.html',
      thumbnail: mamondeSkin,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '메이크업 전체보기',
      url: 'https://www.mamonde.com/kr/ko/product/makeup/category/all/index.html',
      thumbnail: mamondeMakeUp,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/mamondekorea',
      urlId: 'mamondekorea',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/mamonde.kr',
      urlId: 'mamonde.kr',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/user/mamonderepair',
      urlId: 'user/mamonderepair',
    },
    {
      category: 'Homepage',
      url: 'https://www.mamonde.com/kr/ko/index.html',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
export const spicyRamenLinkList = {
  profile: {
    dongwonLogo: spicyRamenLogo,
    nickname: 'spicyRamen',
  },
  theme: {
    backgroundImage: spicyRamenBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '불닭볶음면의 소개와 종류',
      url: 'https://www.samyangfoods.com/kor/brand/spicyRamen.do',
      thumbnail: spicyRamenProduct,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '삼양라면 브랜드 소개',
      url: 'https://www.samyangfoods.com/kor/brand/samyangRamen.do',
      thumbnail: spicyRamenBrand,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '고객 지원',
      url: 'https://www.samyangfoods.com/kor/content/sound/index.do',
      thumbnail: spicyRamenContact,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/samyangfoods',
      urlId: 'samyangfoods',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/samyangfoods',
      urlId: 'samyangfoods',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/samyangfoods',
      urlId: 'samyangfoods',
    },
    {
      category: 'Homepage',
      url: 'https://www.samyangfoods.com/kor/index.do',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
export const samsungBudsLinkList = {
  profile: {
    dongwonLogo: samsungBudsLogo,
    nickname: 'samsung_buds',
  },
  theme: {
    backgroundImage: samsungBudsBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '갤럭시 버즈2 프로',
      url: 'https://www.samsung.com/sec/buds/all-buds/?galaxy-buds2-pro',
      thumbnail: samsungBuds2,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '갤럭시 버즈 프로',
      url: 'https://www.samsung.com/sec/buds/all-buds/?galaxy-buds-pro',
      thumbnail: samsungBuds,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '스페셜 에디션',
      url: 'https://www.samsung.com/sec/buds/all-buds/?buds-special-edition',
      thumbnail: samsungEdition,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/samsungkorea',
      urlId: 'samsungkorea',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/SamsungKorea',
      urlId: 'SamsungKorea',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/c/samsungkorea',
      urlId: 'c/samsungkorea',
    },
    {
      category: 'Homepage',
      url: 'https://www.samsung.com/sec/',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
export const penmaxLinkList = {
  profile: {
    dongwonLogo: penmaxLogo,
    nickname: 'penmax',
  },
  theme: {
    backgroundImage: penmaxBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '제품 보기',
      url: 'http://www.seoheung.co.kr/home/sub/official_pgildan',
      thumbnail: penmaxProduct,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '공지 사항',
      url: 'http://www.seoheung.co.kr/home/sub/bbs_notice',
      thumbnail: penmaxNotice,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '고객 지원',
      url: 'http://www.seoheung.co.kr/home/sub/bbs_qna',
      thumbnail: penmaxCantact,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    // {
    //   category: 'Instagram',
    //   url: 'https://www.instagram.com/',
    //   urlId: '',
    // },
    // {
    //   category: 'Facebook',
    //   url: 'https://www.facebook.com/',
    //   urlId: '',
    // },
    // {
    //   category: 'Youtube',
    //   url: 'https://www.youtube.com/',
    //   urlId: '',
    // },
    {
      category: 'Homepage',
      url: 'http://www.seoheung.co.kr/home/info/1980',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
export const marvelLinkList = {
  profile: {
    dongwonLogo: marvelLogo,
    nickname: 'marvel',
  },
  theme: {
    backgroundImage: marvelBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: 'MOVIES',
      url: 'https://www.marvel.com/movies',
      thumbnail: marvelMovies,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: 'COMICS',
      url: 'https://www.marvel.com/comics',
      thumbnail: marvelComics,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: 'NEWS',
      url: 'https://www.marvel.com/articles',
      thumbnail: marvelNews,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/marvel',
      urlId: 'marvel',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/Marvel',
      urlId: 'Marvel',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/marvel',
      urlId: 'marvel',
    },
    {
      category: 'Homepage',
      url: 'https://www.marvel.com/',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
export const cjlogisticsLinkList = {
  profile: {
    dongwonLogo: cjlogisticsLogo,
    nickname: 'cjlogistics',
  },
  theme: {
    backgroundImage: cjlogisticsBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '택배 배송정보 확인',
      url: 'https://www.cjlogistics.com/ko/tool/parcel/tracking',
      thumbnail: cjlogisticsConfirm,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '사업 소개',
      url: 'https://www.cjlogistics.com/ko/business',
      thumbnail: cjlogisticsInfo,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '고객 지원',
      url: 'https://www.cjlogistics.com/ko/support/contacts',
      thumbnail: cjlogisticsContact,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/cjlogistics.official/?hl=ko',
      urlId: 'cjlogistics.official/?hl=ko',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/CJLogisticsglobal',
      urlId: 'CJLogisticsglobal',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/cjlogistics',
      urlId: 'cjlogistics',
    },
    {
      category: 'Homepage',
      url: 'https://www.cjlogistics.com/ko/main',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
export const mobisLinkList = {
  profile: {
    dongwonLogo: mobisLogo,
    nickname: 'mobis',
  },
  theme: {
    backgroundImage: mobisBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '현대 모비스 소개',
      url: 'https://www.mobis.co.kr/kr/aboutus/aboutus.do#introduce',
      thumbnail: mobisInfo,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '인재 채용',
      url: 'https://www.mobis.co.kr/kr/aboutus/careers.do',
      thumbnail: mobisCareer,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '뉴스룸',
      url: 'https://www.mobis.co.kr/kr/aboutus/press.do',
      thumbnail: mobisNews,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    // {
    //   category: 'Instagram',
    //   url: 'https://www.instagram.com/',
    //   urlId: '',
    // },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/mobisworld',
      urlId: 'mobisworld',
    },
    // {
    //   category: 'Youtube',
    //   url: 'https://www.youtube.com/',
    //   urlId: '',
    // },
    {
      category: 'Homepage',
      url: 'https://www.mobis.co.kr/kr/index.do',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
export const vangoghmuseumLinkList = {
  profile: {
    dongwonLogo: vangoghmuseumLogo,
    nickname: 'vangoghmuseum',
  },
  theme: {
    backgroundImage: vangoghmuseumBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: 'Tickets & Visit',
      url: 'https://www.vangoghmuseum.nl/en/visit/tickets-and-ticket-prices',
      thumbnail: vangoghmuseumTickets,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: 'Art & Stories',
      url: 'https://www.vangoghmuseum.nl/en/art-and-stories/art\n',
      thumbnail: vangoghmuseumArt,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: 'Webstore',
      url: 'https://www.vangoghmuseumshop.com/en/shop',
      thumbnail: vangoghmuseumWebstore,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    // {
    //   category: 'Instagram',
    //   url: 'https://www.instagram.com/',
    //   urlId: '',
    // },
    // {
    //   category: 'Facebook',
    //   url: 'https://www.facebook.com/',
    //   urlId: '',
    // },
    // {
    //   category: 'Youtube',
    //   url: 'https://www.youtube.com/',
    //   urlId: '',
    // },
    {
      category: 'Homepage',
      url: 'https://www.vangoghmuseum.nl/nl',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
export const muzikLinkList = {
  profile: {
    dongwonLogo: muzikLogo,
    nickname: 'muzik',
  },
  theme: {
    backgroundImage: muzikBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '이벤트',
      url: 'https://ra.co/events/kr/seoul',
      thumbnail: muzikEvent,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '음악',
      url: 'https://ra.co/music',
      thumbnail: muzikMusic,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '매거진',
      url: 'https://ra.co/magazine',
      thumbnail: muzikMagazin,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/resident_advisor',
      urlId: 'resident_advisor',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/residentadvisor',
      urlId: 'residentadvisor',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/residentadvisor',
      urlId: 'residentadvisor',
    },
    {
      category: 'Homepage',
      url: 'https://ra.co/',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
export const louvreLinkList = {
  profile: {
    dongwonLogo: louvreLogo,
    nickname: 'louvre',
  },
  theme: {
    backgroundImage: louvreBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: 'VISITER',
      url: 'https://www.louvre.fr/visiter',
      thumbnail: louvreVisiter,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: 'DÉCOUVRIR',
      url: 'https://www.louvre.fr/decouvrir',
      thumbnail: louvreDecouvrir,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: 'EN CE MOMENT',
      url: 'https://www.louvre.fr/en-ce-moment',
      thumbnail: louvreEnceMoment,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/museelouvre',
      urlId: '',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/museedulouvre',
      urlId: '',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/user/louvre',
      urlId: '',
    },
    {
      category: 'Homepage',
      url: 'https://www.louvre.fr/',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
export const gwanakLinkList = {
  profile: {
    dongwonLogo: gwanakLogo,
    nickname: 'gwanak',
  },
  theme: {
    backgroundImage: gwanakBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '관악 소개',
      url: 'https://www.gwanak.go.kr/site/gwanak/11/11101010000002016051207.jsp',
      thumbnail: gwanakInfo,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '참여 / 예약',
      url: 'https://www.gwanak.go.kr/site/gwanak/02/10201030100002016051202.jsp',
      thumbnail: gwanakSave,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '민원 접수',
      url: 'https://www.gwanak.go.kr/site/gwanak/ex/civilManual/CivilManualFList.do',
      thumbnail: gwanakContact,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    // {
    //   category: 'Instagram',
    //   url: 'https://www.instagram.com/',
    //   urlId: '',
    // },
    // {
    //   category: 'Facebook',
    //   url: 'https://www.facebook.com/',
    //   urlId: '',
    // },
    // {
    //   category: 'Youtube',
    //   url: 'https://www.youtube.com/',
    //   urlId: '',
    // },
    {
      category: 'Homepage',
      url: 'https://www.gwanak.go.kr/site/gwanak/main.do',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};

export const cartier100001LinkList = {
  profile: {
    dongwonLogo: cartierWineLogo,
    nickname: '100001',
  },
  theme: {
    backgroundImage: cartierWineBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: 'Commercial',
      url: 'https://www.youtube.com/watch?v=kVXm9JOl1XA',
      thumbnail: cartierWineBell,
      category: 'Youtube',
      urlId: 'watch?v=kVXm9JOl1XA',
    },
    {
      id: 2,
      name: 'Fake VS. Real',
      url: 'https://www.swisswatchexpo.com/TheWatchClub/2019/02/08/how-to-spot-a-fake-cartier-watch/',
      thumbnail: cartierWineEye,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: 'Unboxing',
      url: 'https://www.youtube.com/watch?v=2gxAbVchU8Q',
      thumbnail: cartierWineBoxing,
      category: 'Youtube',
      urlId: 'watch?v=2gxAbVchU8Q',
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/cartier',
      urlId: 'cartier',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/cartier.korea/?brand_redir=203621737645',
      urlId: 'cartier.korea/?brand_redir=203621737645',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/cartier',
      urlId: 'cartier',
    },
    {
      category: 'Homepage',
      url: 'https://www.cartier.com',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
export const cartier100002LinkList = {
  profile: {
    dongwonLogo: cartierWineLogo,
    nickname: '100002',
  },
  theme: {
    backgroundImage: cartierWineBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: 'Commercial',
      url: 'https://www.youtube.com/watch?v=kVXm9JOl1XA',
      thumbnail: cartierWineBell,
      category: 'Youtube',
      urlId: 'watch?v=kVXm9JOl1XA',
    },
    {
      id: 2,
      name: 'Fake VS. Real',
      url: 'https://www.swisswatchexpo.com/TheWatchClub/2019/02/08/how-to-spot-a-fake-cartier-watch/',
      thumbnail: cartierWineEye,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: 'Unboxing',
      url: 'https://www.youtube.com/watch?v=2gxAbVchU8Q',
      thumbnail: cartierWineBoxing,
      category: 'Youtube',
      urlId: 'watch?v=2gxAbVchU8Q',
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/cartier',
      urlId: 'cartier',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/cartier.korea/?brand_redir=203621737645',
      urlId: 'cartier.korea/?brand_redir=203621737645',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/cartier',
      urlId: 'cartier',
    },
    {
      category: 'Homepage',
      url: 'https://www.cartier.com',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
export const cartier000001LinkList = {
  profile: {
    dongwonLogo: cartierGrayLogo,
    nickname: '000001',
  },
  theme: {
    backgroundImage: cartierGrayBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: 'Commercial',
      url: 'https://www.youtube.com/watch?v=SSnVP-X_CLc',
      thumbnail: cartierGrayBell,
      category: 'Youtube',
      urlId: 'watch?v=SSnVP-X_CLc',
    },
    {
      id: 2,
      name: 'Fake VS. Real',
      url: 'https://legitcheck.app/guides/watches/iwc/real-vs-fake-iwc-portuguese-pilot-portofino/',
      thumbnail: cartierGrayEye,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: 'Unboxing',
      url: 'https://www.youtube.com/watch?v=lzXZa72AGnA',
      thumbnail: cartierGrayBoxing,
      category: 'Youtube',
      urlId: 'watch?v=lzXZa72AGnA',
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/iwcwatches',
      urlId: 'iwcwatches',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/IWCWatches',
      urlId: 'IWCWatches',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/iwcwatches',
      urlId: 'iwcwatches',
    },
    {
      category: 'Homepage',
      url: 'https://www.iwc.com',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
export const cartier000002LinkList = {
  profile: {
    dongwonLogo: cartierGrayLogo,
    nickname: '000002',
  },
  theme: {
    backgroundImage: cartierGrayBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: 'Commercial',
      url: 'https://www.youtube.com/watch?v=SSnVP-X_CLc',
      thumbnail: cartierGrayBell,
      category: 'Youtube',
      urlId: 'watch?v=SSnVP-X_CLc',
    },
    {
      id: 2,
      name: 'Fake VS. Real',
      url: 'https://legitcheck.app/guides/watches/iwc/real-vs-fake-iwc-portuguese-pilot-portofino/',
      thumbnail: cartierGrayEye,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: 'Unboxing',
      url: 'https://www.youtube.com/watch?v=lzXZa72AGnA',
      thumbnail: cartierGrayBoxing,
      category: 'Youtube',
      urlId: 'watch?v=lzXZa72AGnA',
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/iwcwatches',
      urlId: 'iwcwatches',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/IWCWatches',
      urlId: 'IWCWatches',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/iwcwatches',
      urlId: 'iwcwatches',
    },
    {
      category: 'Homepage',
      url: 'https://www.iwc.com',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};

export const logen001LinkList = {
  profile: {
    dongwonLogo: logenLogo,
    nickname: 'logen 001',
  },
  theme: {
    backgroundImage: logenBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '고객 문의',
      url: 'https://www.ilogen.com/m/customer/cs',
      thumbnail: logenCall,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '예약 확인',
      url: 'https://www.ilogen.com/m/reservation/list',
      thumbnail: logenPeople,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '지점 안내',
      url: 'https://www.ilogen.com/m/network/centerInfo',
      thumbnail: logenOffice,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    // {
    //   category: 'Instagram',
    //   url: 'https://www.instagram.com/iwcwatches',
    //   urlId: 'iwcwatches',
    // },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/logenlogistics',
      urlId: 'logenlogistics',
    },
    // {
    //   category: 'Youtube',
    //   url: 'https://www.youtube.com/iwcwatches',
    //   urlId: 'iwcwatches',
    // },
    {
      category: 'Homepage',
      url: 'https://www.ilogen.com/m',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};

export const logen002LinkList = {
  profile: {
    dongwonLogo: logenLogo,
    nickname: 'logen 002',
  },
  theme: {
    backgroundImage: logenBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '고객 문의',
      url: 'https://www.ilogen.com/m/customer/cs',
      thumbnail: logenCall,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '예약 확인',
      url: 'https://www.ilogen.com/m/reservation/list',
      thumbnail: logenPeople,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '지점 안내',
      url: 'https://www.ilogen.com/m/network/centerInfo',
      thumbnail: logenOffice,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    // {
    //   category: 'Instagram',
    //   url: 'https://www.instagram.com/iwcwatches',
    //   urlId: 'iwcwatches',
    // },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/logenlogistics',
      urlId: 'logenlogistics',
    },
    // {
    //   category: 'Youtube',
    //   url: 'https://www.youtube.com/iwcwatches',
    //   urlId: 'iwcwatches',
    // },
    {
      category: 'Homepage',
      url: 'https://www.ilogen.com/m',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};

export const logen003LinkList = {
  profile: {
    dongwonLogo: logenLogo,
    nickname: 'logen 003',
  },
  theme: {
    backgroundImage: logenBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '고객 문의',
      url: 'https://www.ilogen.com/m/customer/cs',
      thumbnail: logenCall,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '예약 확인',
      url: 'https://www.ilogen.com/m/reservation/list',
      thumbnail: logenPeople,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '지점 안내',
      url: 'https://www.ilogen.com/m/network/centerInfo',
      thumbnail: logenOffice,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    // {
    //   category: 'Instagram',
    //   url: 'https://www.instagram.com/iwcwatches',
    //   urlId: 'iwcwatches',
    // },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/logenlogistics',
      urlId: 'logenlogistics',
    },
    // {
    //   category: 'Youtube',
    //   url: 'https://www.youtube.com/iwcwatches',
    //   urlId: 'iwcwatches',
    // },
    {
      category: 'Homepage',
      url: 'https://www.ilogen.com/m',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
export const looxLooe110001LinkList = {
  profile: {
    dongwonLogo: looxLooeLogo,
    nickname: '110001',
  },
  theme: {
    backgroundImage: looxLooeBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '제품 상세',
      url: 'https://www.looxloo.com/product/detail_brand.html?product_no=16885&brand_name_en=BLUEDOG&brand_code=B000000I&shop_no=1&brand_type=A',
      thumbnail: looxLooeProduct,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '2023 스프링 룩북',
      url: 'https://makevu.me/fc004898c9',
      thumbnail: looxLooeLook,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '룩스루 추천템',
      url: 'https://www.youtube.com/shorts/re4wDsmq5sE',
      thumbnail: looxLooeItem,
      category: 'Youtube',
      urlId: 'shorts/re4wDsmq5sE',
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/looxloo_official',
      urlId: 'looxloo_official',
    },
    // {
    //   category: 'Facebook',
    //   url: 'https://www.facebook.com/logenlogistics',
    //   urlId: 'logenlogistics',
    // },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/@LOOXLOOOFFICIAL',
      urlId: '@LOOXLOOOFFICIAL',
    },
    {
      category: 'Homepage',
      url: 'https://www.looxloo.com/index.html ',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
export const looxLooe110002LinkList = {
  profile: {
    dongwonLogo: looxLooeLogo,
    nickname: '110002',
  },
  theme: {
    backgroundImage: looxLooeBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '제품 상세',
      url: 'https://www.looxloo.com/product/detail_brand.html?product_no=16885&brand_name_en=BLUEDOG&brand_code=B000000I&shop_no=1&brand_type=A',
      thumbnail: looxLooeProduct,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '2023 스프링 룩북',
      url: 'https://makevu.me/fc004898c9',
      thumbnail: looxLooeLook,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '룩스루 추천템',
      url: 'https://www.youtube.com/shorts/re4wDsmq5sE',
      thumbnail: looxLooeItem,
      category: 'Youtube',
      urlId: 'shorts/re4wDsmq5sE',
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/looxloo_official',
      urlId: 'looxloo_official',
    },
    // {
    //   category: 'Facebook',
    //   url: 'https://www.facebook.com/logenlogistics',
    //   urlId: 'logenlogistics',
    // },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/@LOOXLOOOFFICIAL',
      urlId: '@LOOXLOOOFFICIAL',
    },
    {
      category: 'Homepage',
      url: 'https://www.looxloo.com/index.html ',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
export const baba120001LinkList = {
  profile: {
    dongwonLogo: babaLogo,
    nickname: '120001',
  },
  theme: {
    backgroundImage: babaBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '제품 상세',
      url: 'https://pc.babathe.com/goods/indexGoodsDetail?goodsId=G322983008&searchQuery=ALL&dqId=',
      thumbnail: babaProduct,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '2023 BABA 신상품',
      url: 'https://babathe.com/goodsnew/indexNew',
      thumbnail: babaNew,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: 'IZZAT BABA "the COATS"',
      url: 'https://www.youtube.com/shorts/q5EbL8vPBrk',
      thumbnail: babaIzzat,
      category: 'Youtube',
      urlId: 'shorts/re4wDsmq5sE',
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/babathe_official',
      urlId: 'babathe_official',
    },
    // {
    //   category: 'Facebook',
    //   url: 'https://www.facebook.com/logenlogistics',
    //   urlId: 'logenlogistics',
    // },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/channel/UCe4xgEoigrAyDL_Xqe79jhQ',
      urlId: 'channel/UCe4xgEoigrAyDL_Xqe79jhQ',
    },
    {
      category: 'Homepage',
      url: 'https://babathe.com',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
export const baba120002LinkList = {
  profile: {
    dongwonLogo: babaLogo,
    nickname: '120002',
  },
  theme: {
    backgroundImage: babaBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '제품 상세',
      url: 'https://pc.babathe.com/goods/indexGoodsDetail?goodsId=G322983008&searchQuery=ALL&dqId=',
      thumbnail: babaProduct,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '2023 BABA 신상품',
      url: 'https://babathe.com/goodsnew/indexNew',
      thumbnail: babaNew,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: 'IZZAT BABA "the COATS"',
      url: 'https://www.youtube.com/shorts/q5EbL8vPBrk',
      thumbnail: babaIzzat,
      category: 'Youtube',
      urlId: 'shorts/re4wDsmq5sE',
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/babathe_official',
      urlId: 'babathe_official',
    },
    // {
    //   category: 'Facebook',
    //   url: 'https://www.facebook.com/logenlogistics',
    //   urlId: 'logenlogistics',
    // },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/channel/UCe4xgEoigrAyDL_Xqe79jhQ',
      urlId: 'channel/UCe4xgEoigrAyDL_Xqe79jhQ',
    },
    {
      category: 'Homepage',
      url: 'https://babathe.com',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};

export const theoriginalLinkList = {
  profile: {
    dongwonLogo: theoriginalLogo,
    nickname: 'theoriginal',
  },
  theme: {
    backgroundImage: theoriginalBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '공연 초대권 이벤트',
      url: 'https://www.instagram.com/p/CpgplUbSjES/',
      thumbnail: theoriginalHome,
      category: 'Instagram',
      urlId: 'p/CpgplUbSjES/',
    },
    {
      id: 2,
      name: 'VIENNA FESTA 2023',
      url: 'https://www.instagram.com/p/CpRub8uOyu2/',
      thumbnail: theoriginalNew,
      category: 'Instagram',
      urlId: 'p/CpRub8uOyu2/',
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/orchestra_the_original',
      urlId: 'orchestra_the_original',
    },
    // {
    //   category: 'Facebook',
    //   url: 'https://www.facebook.com/logenlogistics',
    //   urlId: 'logenlogistics',
    // },
    // {
    //   category: 'Youtube',
    //   url: 'https://www.youtube.com/channel/UCe4xgEoigrAyDL_Xqe79jhQ',
    //   urlId: 'channel/UCe4xgEoigrAyDL_Xqe79jhQ',
    // },
    // {
    //   category: 'Homepage',
    //   url: 'http://theoriginal.kr',
    // },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};

export const whoauLinkList = {
  profile: {
    dongwonLogo: whoauLogo,
    nickname: 'whoau',
  },
  theme: {
    backgroundImage: whoauBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: 'New Collection',
      url: 'https://whoau.com/product/list.html?cate_no=23',
      thumbnail: whoauNew,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: '23CC TENNIS COLLECTION',
      url: 'https://whoau.com/product/list.html?cate_no=28',
      thumbnail: whoauBest,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 3,
      name: '스티브 반팔 카라티 체험단',
      url: 'https://whoau.com/board/event/read.html?no=6646&board_no=1002',
      thumbnail: whoauEvent,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/whoaukorea',
      urlId: 'whoaukorea',
    },
    {
      category: 'Facebook',
      url: 'https://www.facebook.com/whoaukorea',
      urlId: 'whoaukorea',
    },
    {
      category: 'Youtube',
      url: 'https://www.youtube.com/channel/UC1o5U5AYGqV4DwP7fj4qrvQ',
      urlId: 'channel/UC1o5U5AYGqV4DwP7fj4qrvQ',
    },
    {
      category: 'Homepage',
      url: 'https://whoau.com',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
export const tamnaalesLinkList = {
  profile: {
    dongwonLogo: tamnaalesLogo,
    nickname: 'tamnaales',
  },
  theme: {
    backgroundImage: tamnaalesBG,
    themeName: 'dongwon',
    font: 'Noto Sans KR',
  },
  list: [
    {
      id: 1,
      name: '우리술 양조장[서귀포]탐라에일',
      url: 'https://m.blog.naver.com/dnr6578/222264137318',
      thumbnail: tamnaalesBlog,
      category: 'Homepage',
      urlId: null,
    },
    {
      id: 2,
      name: 'Tistory 수제맥주 탐라에일',
      url: 'https://tamnaales.tistory.com/m/',
      thumbnail: tamnaalesTistory,
      category: 'Homepage',
      urlId: null,
    },
  ],
  snsList: [
    {
      category: 'Instagram',
      url: 'https://www.instagram.com/tamna_ales',
      urlId: 'tamna_ales',
    },
    // {
    //   category: 'Facebook',
    //   url: 'https://www.facebook.com/whoaukorea',
    //   urlId: 'whoaukorea',
    // },
    // {
    //   category: 'Youtube',
    //   url: 'https://www.youtube.com/channel/UC1o5U5AYGqV4DwP7fj4qrvQ',
    //   urlId: 'channel/UC1o5U5AYGqV4DwP7fj4qrvQ',
    // },
    {
      category: 'Homepage',
      url: 'http://www.tamnaales.com',
    },
    // {
    //   category: 'Discord',
    //   url: 'https://discord.com/',
    // },
    // {
    //   category: 'Email',
    //   url: 'https://www.naver.com/',
    // },
    // {
    //   category: 'Tiktok',
    //   url: 'https://www.tiktok.com/ko-KR/',
    // },
    // {
    //   category: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    //
    // {
    //   category: 'Playstore',
    //   url: 'https://play.google.com/',
    // },
  ],
  feedList: [],
};
