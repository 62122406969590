import React, { FC } from 'react';
import { BannerImage, ProfileImage, ProfileName, ProfileSection, SphhLogoImage } from './styles';
import profileImage from 'assets/images/winter.jpeg';
import { useLocation } from 'react-router-dom';

interface Props {
  profile?: any;
  themeName?: string;
}

const Profile: FC<Props> = ({ profile, themeName }) => {
  const location = useLocation();
  const pathname = location.pathname;
  // console.log(location.pathname === '/incellderm_official');
  return (
    <ProfileSection
      className={`${themeName === 'sphh' ? 'sphh' : ''}${
        themeName === 'dongwon' || themeName === 'kiff' || themeName === 'mhsc' ? 'dongwon' : ''
      } ${
        pathname === '/100001' ||
        pathname === '/100002' ||
        pathname === '/000001' ||
        pathname === '/000002' ||
        pathname === '/200001' ||
        pathname === '/200002' ||
        pathname === '/110001' ||
        pathname === '/110002' ||
        pathname === '/120001' ||
        pathname === '/120002'
          ? `bigName`
          : ''
      }`}
    >
      {profile.bannerUrl ? (
        <BannerImage image={profile.bannerUrl} />
      ) : themeName === 'sphh' ? (
        <SphhLogoImage src={profile.sphhLogo} alt="sphhLogo" width="100%" />
      ) : themeName === 'dongwon' || themeName === 'kiff' || themeName === 'mhsc' ? (
        <>
          <ProfileImage>
            <img src={profile.dongwonLogo} alt="profileImage" />
          </ProfileImage>
          <ProfileName
            className={
              pathname === '/100001' ||
              pathname === '/100002' ||
              pathname === '/000001' ||
              pathname === '/000002' ||
              pathname === '/200001' ||
              pathname === '/200002' ||
              pathname === '/110001' ||
              pathname === '/110002' ||
              pathname === '/120001' ||
              pathname === '/120002'
                ? `bigName`
                : pathname === '/logen001' || pathname === '/logen002' || pathname === '/logen003'
                ? `midName`
                : `dongwon`
            }
          >
            @{profile.nickname}
          </ProfileName>
        </>
      ) : (
        <>
          <ProfileImage>
            <img src={profileImage} alt="profileImage" />
          </ProfileImage>
          <ProfileName>@Winter</ProfileName>
        </>
      )}
      {location.pathname === '/incellderm_official' && <div style={{ fontSize: '20px' }}>테스트버전입니다.</div>}
    </ProfileSection>
  );
};

export default Profile;
