import React, { useCallback } from 'react';
import MainLinkTreeComponent from 'components/MainLinkTreeComponent';
import Layout from 'layout/Layout';
import {
  bbqLinkList,
  dongwonLinkList,
  louisquatorzeLinkList,
  pascucciLinkList,
  bebecookLinkList,
  samlipsaladLinkList,
  sphhLinkList,
  tonymolyLinkList,
  opriceLinkList,
  monnaLinkList,
  kifLinkList,
  godivaList,
  watergList,
  mhscLinkList,
  btsLinkList,
  isaacLinkList,
  kgcshopLinkList,
  incelldermLinkList,
  cartier100001LinkList,
  cartier100002LinkList,
  cartier000001LinkList,
  cartier000002LinkList,
  kgcshop200001LinkList,
  kgcshop200002LinkList,
  logen001LinkList,
  logen002LinkList,
  logen003LinkList,
  chanelLinkList,
  lotteShoppingLinkList,
  pfizerShoppingLinkList,
  mamondeLinkList,
  spicyRamenLinkList,
  samsungBudsLinkList,
  penmaxLinkList,
  marvelLinkList,
  cjlogisticsLinkList,
  mobisLinkList,
  vangoghmuseumLinkList,
  muzikLinkList,
  louvreLinkList,
  gwanakLinkList,
  looxLooe110001LinkList,
  looxLooe110002LinkList,
  baba120001LinkList,
  baba120002LinkList,
  theoriginalLinkList,
  whoauLinkList,
  tamnaalesLinkList,
} from 'utils/dummy';
import { useLocation } from 'react-router-dom';

const MainLinkTreePage = () => {
  const location = useLocation();
  const themePath = useCallback(() => {
    if (location.pathname === '/sphh') {
      return sphhLinkList;
    } else if (location.pathname === '/dongwonfnb') {
      return dongwonLinkList;
    } else if (location.pathname === '/tonymoly') {
      return tonymolyLinkList;
    } else if (location.pathname === '/pascucci') {
      return pascucciLinkList;
    } else if (location.pathname === '/louisquatorze') {
      return louisquatorzeLinkList;
    } else if (location.pathname === '/samlip') {
      return samlipsaladLinkList;
    } else if (location.pathname === '/bebecook') {
      return bebecookLinkList;
    } else if (location.pathname === '/bbq') {
      return bbqLinkList;
    } else if (location.pathname === '/oprice') {
      return opriceLinkList;
    } else if (location.pathname === '/monna') {
      return monnaLinkList;
    } else if (location.pathname === '/2022kif') {
      return kifLinkList;
    } else if (location.pathname === '/2022mhsc') {
      return mhscLinkList;
    } else if (location.pathname === '/godiva') {
      return godivaList;
    } else if (location.pathname === '/waterg') {
      return watergList;
    } else if (location.pathname === '/bts_guess') {
      return btsLinkList;
    } else if (location.pathname === '/isaac') {
      return isaacLinkList;
    } else if (location.pathname === '/kgcshop') {
      return kgcshopLinkList;
    } else if (location.pathname === '/chanel') {
      return chanelLinkList;
    } else if (location.pathname === '/lotteshopping') {
      return lotteShoppingLinkList;
    } else if (location.pathname === '/pfizer') {
      return pfizerShoppingLinkList;
    } else if (location.pathname === '/mamonde') {
      return mamondeLinkList;
    } else if (location.pathname === '/spicyRamen') {
      return spicyRamenLinkList;
    } else if (location.pathname === '/samsung_buds') {
      return samsungBudsLinkList;
    } else if (location.pathname === '/penmax') {
      return penmaxLinkList;
    } else if (location.pathname === '/marvel') {
      return marvelLinkList;
    } else if (location.pathname === '/cjlogistics') {
      return cjlogisticsLinkList;
    } else if (location.pathname === '/vangoghmuseum') {
      return vangoghmuseumLinkList;
    } else if (location.pathname === '/mobis') {
      return mobisLinkList;
    } else if (location.pathname === '/muzik') {
      return muzikLinkList;
    } else if (location.pathname === '/louvre') {
      return louvreLinkList;
    } else if (location.pathname === '/gwanak') {
      return gwanakLinkList;
    } else if (location.pathname === '/200001') {
      return kgcshop200001LinkList;
    } else if (location.pathname === '/200002') {
      return kgcshop200002LinkList;
    } else if (location.pathname === '/incellderm_official') {
      return incelldermLinkList;
    } else if (location.pathname === '/100001') {
      return cartier100001LinkList;
    } else if (location.pathname === '/100002') {
      return cartier100002LinkList;
    } else if (location.pathname === '/000001') {
      return cartier000001LinkList;
    } else if (location.pathname === '/000002') {
      return cartier000002LinkList;
    } else if (location.pathname === '/logen001') {
      return logen001LinkList;
    } else if (location.pathname === '/logen002') {
      return logen002LinkList;
    } else if (location.pathname === '/logen003') {
      return logen003LinkList;
    } else if (location.pathname === '/110001') {
      return looxLooe110001LinkList;
    } else if (location.pathname === '/110002') {
      return looxLooe110002LinkList;
    } else if (location.pathname === '/120001') {
      return baba120001LinkList;
    } else if (location.pathname === '/120002') {
      return baba120002LinkList;
    } else if (location.pathname === '/theoriginal') {
      return theoriginalLinkList;
    } else if (location.pathname === '/whoau') {
      return whoauLinkList;
    } else if (location.pathname === '/tamnaales') {
      return tamnaalesLinkList;
    }
  }, [location.pathname]);

  // console.log('location : ', location.pathname);
  return (
    <Layout
      data={themePath()}
      // profile={themePath()} snsList={themePath()} bgImg={themePath()} theme={themePath()}
    >
      <MainLinkTreeComponent data={themePath()} />
    </Layout>
  );
};

export default MainLinkTreePage;
