import React, { FC, useCallback } from 'react';

import { SocialItem, SocialItemBox, SocialListBox, SocialListContainer } from './styles';

// import Discord from 'assets/icons/Discord.svg';
// import Email from 'assets/icons/Email.svg';
// import Instagram from 'assets/icons/Instagram.svg';
// import PlayStore from 'assets/icons/PlayStore.svg';
// import Tiktok from 'assets/icons/Tiktok.svg';
// import Twitter from 'assets/icons/Twitter.svg';
// import Youtube from 'assets/icons/Youtube.svg';
// import Facebook from 'assets/icons/Facebook.svg';
// import Homepage from 'assets/icons/HomePage.svg';

import DiscordIcon from 'assets/icons/DiscordIcon';
import EmailIcon from 'assets/icons/EmailIcon';
import PlayStoreIcon from 'assets/icons/PlayStoreIcon';
import TiktokIcon from 'assets/icons/TiktokIcon';
import FacebookIcon from 'assets/icons/FacebookIcon';
import HomePageIcon from 'assets/icons/HomePageIcon';
import { AiOutlineYoutube, AiOutlineInstagram } from 'react-icons/ai';
import { IconContext } from 'react-icons';
import ReactGA from 'react-ga';

interface Props {
  snsList?: any;
  themeName?: string;
  nickName: string;
}

const SocialList: FC<Props> = ({ snsList, themeName, nickName }) => {
  const handleLink = useCallback(
    (list: any) => {
      // console.log(JSON.stringify(list));
      // window.location.href = `${link}`;
      // window.open(`${link}`, '_blank');
      ReactGA.event({ category: nickName, action: `${nickName} ${list.category}`, label: 'SNS Button' });
      if (window.android) {
        window.android.getLinks(`${JSON.stringify(list)}`);
      } else {
        window.open(`${list.url}`, '_blank');
      }
    },
    [nickName],
  );

  return (
    <SocialListContainer>
      <SocialListBox>
        {snsList.map((list: any, index: number) => (
          <SocialItemBox
            key={index}
            className={`${themeName === 'sphh' ? 'sphh' : ''}${themeName === 'dongwon' ? 'dongwon' : ''}`}
          >
            <SocialItem
              className={`${themeName === 'sphh' ? 'sphh' : ''}${themeName === 'dongwon' ? 'dongwon' : ''}`}
              onClick={() => handleLink(list)}
            >
              {list.category === 'Discord' ? (
                <DiscordIcon color={`${themeName === 'sphh' ? 'black' : ''}${themeName === 'dongwon' ? '#fff' : ''}`} />
              ) : list.category === 'Email' ? (
                <EmailIcon color={`${themeName === 'sphh' ? 'black' : ''}${themeName === 'dongwon' ? '#fff' : ''}`} />
              ) : list.category === 'Instagram' ? (
                <IconContext.Provider
                  value={{
                    color: `${themeName === 'sphh' ? 'black' : ''}${themeName === 'dongwon' ? '#fff' : ''}`,
                    style: { width: '25px', height: '25px' },
                  }}
                >
                  <AiOutlineInstagram />
                </IconContext.Provider>
              ) : // <InstagramIcon
              //   color={`${themeName === 'sphh' ? 'black' : ''}${themeName === 'dongwon' ? '#fff' : ''}`}
              // />
              list.category === 'Playstore' ? (
                <PlayStoreIcon
                  color={`${themeName === 'sphh' ? 'black' : ''}${themeName === 'dongwon' ? '#fff' : ''}`}
                />
              ) : list.category === 'Tiktok' ? (
                <TiktokIcon color={`${themeName === 'sphh' ? 'black' : ''}${themeName === 'dongwon' ? '#fff' : ''}`} />
              ) : list.category === 'Twitter' ? (
                <TiktokIcon color={`${themeName === 'sphh' ? 'black' : ''}${themeName === 'dongwon' ? '#fff' : ''}`} />
              ) : list.category === 'Facebook' ? (
                <FacebookIcon
                  color={`${themeName === 'sphh' ? 'black' : ''}${themeName === 'dongwon' ? '#fff' : ''}`}
                />
              ) : list.category === 'Homepage' ? (
                <HomePageIcon
                  color={`${themeName === 'sphh' ? 'black' : ''}${themeName === 'dongwon' ? '#fff' : ''}`}
                />
              ) : (
                list.category === 'Youtube' && (
                  <IconContext.Provider
                    value={{
                      color: `${themeName === 'sphh' ? 'black' : ''}${themeName === 'dongwon' ? '#fff' : ''}`,
                      style: { width: '25px', height: '25px' },
                    }}
                  >
                    <AiOutlineYoutube />
                  </IconContext.Provider>
                )
              )}
            </SocialItem>
          </SocialItemBox>
        ))}
      </SocialListBox>
    </SocialListContainer>
  );
};

export default SocialList;
