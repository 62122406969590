import styled from '@emotion/styled';

export const IntroBackground = styled.section`
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, #3fc8c0 0%, #02a4d5 100%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`