import React from 'react';
import {IntroBackground} from "./styles";

const introComponent = () => {
    return (
        <IntroBackground>
            <div className="intro"> download </div>
        </IntroBackground>
    )
}


export default introComponent;